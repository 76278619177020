"use client";
import { cn } from "@/lib/utils";
import { StreamerInterface } from "@/types";
import Link from "next/link";
import { useEffect, useState } from "react";

type StreamerShowcaseProps = {
  streamers: StreamerInterface[];
  lng: string;
};

export function StreamerShowcase({ streamers, lng }: StreamerShowcaseProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  function handleNext() {
    setActiveIndex((prev) => (prev + 1) % streamers.length);
  }

  function handlePrev() {
    setActiveIndex((prev) => (prev - 1 + streamers.length) % streamers.length);
  }

  function handlePlay() {
    setTimer(
      setInterval(() => {
        setActiveIndex((prev) => (prev + 1) % streamers.length);
      }, 5000)
    );
  }

  function handlePause() {
    clearInterval(timer);
  }

  useEffect(() => {
    handlePlay();
    return () => {
      handlePause();
    };
  }, []);

  return (
    <Link
      href={`/${lng}/${streamers[activeIndex].username}`}
      className={cn(
        "flex-col items-center justify-center space-y-4 hidden md:flex"
      )}
      key={streamers[activeIndex].username}
    >
      <img
        src={
          streamers[activeIndex].twitch?.profile_image_url ||
          streamers[activeIndex].youtube?.profile_image_url
        }
        alt={streamers[activeIndex].twitch?.display_name}
        className={cn(
          "w-32 h-32 rounded-full object-cover",
          streamers[activeIndex].twitch?.live ||
            streamers[activeIndex].youtube?.live
            ? "ring-2 ring-green-500"
            : "ring-2 ring-red-500"
        )}
      />
      <h3 className="mt-4 text-xl font-semibold text-center text-white">
        {streamers[activeIndex].twitch?.display_name ||
          streamers[activeIndex].youtube?.display_name ||
          streamers[activeIndex]?.username}
      </h3>
    </Link>
  );
}
